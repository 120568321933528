import instance from './instance';

export const add = (data) => {
  return instance.post('watermeters/add', data);
};

export const details = (id) => {
  return instance.get(`watermeters/details/${id}`);
};

export const list = (withWatermeter, year) => {
  return instance.get(`watermeters/list?withWatermeter=${withWatermeter ? 'true' : 'false'}&year=${year}`);
};

export const remove = (id) => {
  return instance.delete(`watermeters/remove/${id}`);
};

export const usage = (year) => {
  return instance.get(`watermeters/usage?year=${year}`);
};
