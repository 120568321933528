import Page from '../../Page';
import { details as userDetails } from '../../httpClient/users';
import { details } from '../../httpClient/waterMeters';
import styles from './Details.module.scss';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const URL = `${process.env.REACT_APP_FILES_URL}/watermeters/`;

const Details = () => {
  const { gardenId } = useParams();
  const [list, setList] = useState([]);
  const [user, setUser] = useState();

  useEffect(() => {
    const get = async () => {
      const response = await details(gardenId);
      const userResponse = await userDetails(gardenId);

      setList(response.data);
      setUser(userResponse.data);
    };

    if (gardenId) {
      get();
    }
  }, [gardenId]);

  return (
    <Page
      title={`Odczyty dla działki ${gardenId}`}
      back="/watermeters"
      menu={
        <>
          <Link to={`/watermeters/add/${gardenId}`} className="button-primary">
            Dodaj odczyt
          </Link>
        </>
      }
      breadcrumbs={[['/watermeters', 'Wodomierze']]}
    >
      {user && (
        <div className={styles.user}>
          <div className={styles.userName}>{user.names}</div>
          <div>Rozmiar wodomierza: {user.waterMeterSize === 1 ? '1/2' : '3/4'}''</div>
        </div>
      )}

      <div>
        {list.map((item) => (
          <div key={item.id} className={styles.item}>
            {item.image && (
              <div className={styles.image}>
                <img src={`${URL}${item.image}`} alt="Odczyt" />
              </div>
            )}
            <div className={styles.info}>
              <div>
                <div className={styles.value}>
                  {item.value} m<sup>3</sup>
                </div>
                <div className={styles.data}>{format(new Date(item.time), 'dd.MM.yyyy')}</div>
              </div>
              <div>
                <Link to={`/watermeters/remove/${item.id}`} className="rod-table-button-secondary">
                  Usuń
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Page>
  );
};

export default Details;
