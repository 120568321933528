import PdfDocument from './PdfDocument';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect, useMemo, useRef, useState } from 'react';

const makePriceForQr = (prices) => {
  const value = parseInt(prices.total * 100).toString();

  let trimer = '';

  for (let i = value.length; i < 6; i++) {
    trimer += '0';
  }

  return `${trimer}${value}`;
};

const useBarCodes = (domRef, data) => {
  const codes = useMemo(() => {
    const codes = [];

    if (domRef) {
      for (let i = 0; i < data.length; i++) {
        const value = `OP/${data[i].id}/2023`;
        const options = {
          displayValue: false,
          height: 60,
        };

        const elem = document.createElement('canvas');

        JsBarcode(elem, value, options);

        const dataURL = elem.toDataURL();

        codes.push(dataURL);
      }
    }

    return codes;
  }, [domRef, data]);

  return codes;
};

const useQrs = (data, prices) => {
  const codes = useMemo(() => {
    return data.map((item, index) => {
      return `8512594219|PL|56124039721111001035636279|${makePriceForQr(prices[index])}|ROD im. 1 Maja|OP/${item.id}/2023|||`;
    });
  }, [data, prices]);

  return codes;
};

const Modal = ({ handleClose, data, settings, prices }) => {
  const [show, setShow] = useState(false);
  const [qrUrls, setQrUrls] = useState();

  const divRef = useRef();
  const barsRef = useRef();

  const barUrls = useBarCodes(barsRef, data);
  const qrs = useQrs(data, prices);

  const year = new Date().getFullYear();

  useEffect(() => {
    setTimeout(() => {
      if (divRef.current) {
        const codes = [];

        data.forEach((item, index) => {
          const canvas = divRef.current.childNodes[index];
          const dataURL = canvas.toDataURL();

          codes.push(dataURL);
        });

        setQrUrls(codes);
      }
    }, 200);
  }, [data]);

  useEffect(() => {
    if (qrUrls && barUrls) {
      setTimeout(() => {
        setShow(true);
      }, 200);
    }
  }, [qrUrls, barUrls]);

  const Component =
    qrUrls && barUrls ? (
      <PdfDocument qrUrls={qrUrls} barUrls={barUrls} data={data} settings={settings} prices={prices} year={year} />
    ) : null;

  return (
    <div className="rod-modal">
      <div className="rod-modal-hidden">
        <div ref={divRef}>
          {qrs.map((qr, i) => (
            <QRCodeCanvas key={i} level="L" size={250} value={qr} />
          ))}
        </div>

        <PDFViewer width={1000} height={700}>
          {Component}
        </PDFViewer>
      </div>

      <div className="rod-modal-container">
        {!show && <div className="rod-modal-loading">Generowanie PDF...</div>}

        {show && (
          <div>
            <div>
              <PDFDownloadLink className="button-primary rod-modal-button" document={Component} fileName={`OP-${year}.pdf`}>
                {({ blob, url, loading, error }) => (loading ? 'Tworzenie łącza...' : 'Pobierz PDF')}
              </PDFDownloadLink>
            </div>
            <div className="rod-modal-close">
              <button onClick={handleClose}>Zamknij okno</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const AllBills = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="button-primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Generuj wszystkie rachunki
      </button>

      {open && (
        <Modal
          handleClose={() => {
            setOpen(false);
          }}
          {...props}
        />
      )}
    </>
  );
};

export default AllBills;
