/* eslint-disable react-hooks/exhaustive-deps */
import PlayfairDisplayRegular from '../../assets/PlayfairDisplay-Regular.ttf';
import RobotoBlackFont from '../../assets/Roboto-Black.ttf';
import RobotoFont from '../../assets/Roboto.ttf';
import { list } from '../../httpClient/users';
import { UsersListState } from '../../store/users';
import split from '../../utils/split';
import logoUrl from './logo.png';
import { Document, Font, Image, PDFDownloadLink, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoFont }, // font-style: normal, font-weight: normal
    { src: RobotoBlackFont, fontWeight: 700 },
  ],
});

Font.register({
  family: 'Playfair',
  fonts: [{ src: PlayfairDisplayRegular }],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 9,
    fontFamily: 'Roboto',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
  },

  cell: {
    padding: 10,
    width: '50%',
  },

  box: {
    border: '2px solid black',
  },

  line: {
    display: 'flex',
    flexDirection: 'row',
  },

  item: {
    width: '20%',
    height: 40,
    textAlign: 'center',
  },

  item2: {
    width: '100%',
    height: 40,
    textAlign: 'center',
  },

  borderRight: {
    borderRight: '1px solid black',
  },

  borderBottom: {
    borderBottom: '1px solid black',
  },

  column: {
    width: '20%',
  },

  doubleLine: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },

  center: {
    width: '60%',
    border: '3px solid black',
    height: 80,
    position: 'relative',
  },

  watermark: {
    position: 'absolute',
    top: 5,
    bottom: 0,
    left: 24,
    right: 0,
    opacity: 0.15,
  },

  text1: {
    marginBottom: 1,
    fontSize: '8px',
    fontWeight: 700,
    marginTop: 7,
  },

  text2: {
    fontSize: '11px',
    fontWeight: 700,
  },

  details: {
    alignItems: 'center',
    position: 'relative',
  },

  borderLine: {
    borderTop: '1px solid black',
    width: '70%',
    marginBottom: 2,
    marginTop: 14,
  },

  detailsText: {
    fontSize: '6px',
    fontWeight: 700,
  },

  number: {
    fontFamily: 'Playfair',
    fontSize: '15px',
  },

  noise: {
    position: 'absolute',
    top: 4,
    height: 15,
    left: 40,
    width: 60,
    opacity: 1,
  },

  noiseItem: {
    width: 1,
    height: 1,
    backgroundColor: 'white',
    position: 'absolute',
  },
});

const texts = [
  ['Rezerwa', 'M-I'],
  ['Kiełbasa', '90 g'],
  ['Napój', '500 ml'],
  ['Ciasto', '150 g'],
  ['Ziemniak', '80 g'],
  ['Chleb', '50 g'],
  ['Ogórek', '55 g'],
  ['Rezerwa', '2023-9'],
];

const Details = ({ i }) => (
  <>
    <Text style={styles.text1}>{texts[i][0]}</Text>
    <Text style={styles.text2}>{texts[i][1]}</Text>
  </>
);

const proportion = ([w, h], height) => {
  return {
    height,
    width: (w / h) * height,
  };
};

const howManyItems = 150;
const dots = [];
for (let i = 0; i < howManyItems; i++) {
  dots.push(i);
}

const Noise = ({ w, h }) => {
  return (
    <View style={styles.noise}>
      {dots.map((key) => (
        <View
          key={key}
          style={[
            styles.noiseItem,
            {
              top: Math.ceil(Math.random() * h),
              left: Math.ceil(Math.random() * w),
              opacity: 0.7 + Math.random() * 0.3,
            },
          ]}
        ></View>
      ))}
    </View>
  );
};

const Card = () => {
  return (
    <View style={styles.box}>
      <View style={styles.line}>
        <View style={[styles.item, styles.borderRight, styles.borderBottom]}>
          <Details i={0} />
        </View>
        <View style={[styles.item, styles.borderRight]}>
          <Details i={1} />
        </View>
        <View style={[styles.item, styles.borderRight]}>
          <Details i={1} />
        </View>
        <View style={[styles.item, styles.borderRight]}>
          <Details i={2} />
        </View>
        <View style={[styles.item, styles.borderBottom]}>
          <Details i={2} />
        </View>
      </View>

      <View style={styles.doubleLine}>
        <View style={styles.column}>
          <View style={[styles.item2, styles.borderBottom]}>
            <Details i={3} />
          </View>
          <View style={[styles.item2, styles.borderBottom]}>
            <Details i={3} />
          </View>
        </View>

        <View style={styles.center}>
          <View style={styles.watermark}>
            <Image src={logoUrl} style={{ ...proportion([1236, 563], 40), marginTop: 12 }} />
          </View>

          <View style={styles.details}>
            <Text style={styles.number}>A {Math.ceil(187242 + Math.random() * 720295)}</Text>
            <View style={styles.borderLine}></View>
            <Text style={styles.detailsText}>nazwisko i imię</Text>
            <View style={styles.borderLine}></View>
            <Text style={styles.detailsText}>numer działki</Text>
          </View>

          <Noise w={60} h={15} />
        </View>

        <View style={styles.column}>
          <View style={[styles.item2, styles.borderBottom]}>
            <Details i={4} />
          </View>
          <View style={[styles.item2, styles.borderBottom]}>
            <Details i={4} />
          </View>
        </View>
      </View>

      <View style={styles.line}>
        <View style={[styles.item, styles.borderRight]}>
          <Details i={7} />
        </View>
        <View style={[styles.item, styles.borderRight]}>
          <Details i={6} />
        </View>
        <View style={[styles.item, styles.borderRight]}>
          <Details i={6} />
        </View>
        <View style={[styles.item, styles.borderRight]}>
          <Details i={5} />
        </View>
        <View style={[styles.item]}>
          <Details i={5} />
        </View>
      </View>
    </View>
  );
};

const SinglePage = ({ data, page, pages }) => {
  return (
    <>
      {data.map((row, index) => (
        <View style={styles.row} key={`${page}-${index}`}>
          {row.map((cell, index) => (
            <View style={styles.cell} key={cell.id}>
              <Card data={cell} />
            </View>
          ))}
        </View>
      ))}

      <Text break />
    </>
  );
};

const MyHtml = ({ data }) => {
  const pages = split(data, 4);

  return (
    <Document>
      <Page style={styles.body} wrap={true}>
        {pages.map((page, index) => (
          <SinglePage key={index} data={page} page={index + 1} pages={pages.length} />
        ))}
      </Page>
    </Document>
  );
};

const Modal = ({ handleClose }) => {
  const [users, setUsers] = useRecoilState(UsersListState);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await list();

        setUsers(response.data);
      } catch (e) {
        handleClose();
      }
    };

    getData();
  }, [setUsers]);

  const data = useMemo(() => {
    if (!users) {
      return [];
    }

    const arr = [];

    for (let i = 0; i < users.length; i++) {
      if (i % 2 === 0) {
        arr.push([]);
      }

      arr[arr.length - 1].push(users[i]);
    }

    return arr;
  }, [users]);

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        setShow(true);
      }, 2000);
    }
  }, [data]);

  return (
    <div className="rod-modal">
      {data && (
        <div className="rod-modal-hidden ">
          <PDFViewer>
            <MyHtml data={data} />
          </PDFViewer>
        </div>
      )}

      <div className="rod-modal-container">
        {!show && <div className="rod-modal-loading">Generowanie PDF...</div>}

        {show && (
          <div>
            <div>
              <PDFDownloadLink className="button-primary rod-modal-button" document={<MyHtml data={data} />} fileName="vouchers.pdf">
                {({ blob, url, loading, error }) => (loading ? 'Tworzenie łącza...' : 'Pobierz PDF')}
              </PDFDownloadLink>
            </div>
            <div className="rod-modal-close">
              <button onClick={handleClose}>Zamknij okno</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Vouchers = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="rod-table-button-special"
        onClick={() => {
          setOpen(true);
        }}
      >
        PDF
      </button>

      {open && (
        <Modal
          handleClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Vouchers;
