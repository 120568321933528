import Page from '../../Page';
import { list } from '../../httpClient/news';
import styles from './List.module.scss';
import seenSvg from './assets/seen.svg';
import unseenSvg from './assets/unseen.svg';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const List = () => {
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const get = async () => {
      try {
        const response = await list();

        setDataList(response.data);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    get();
  }, []);

  return (
    <Page
      title="Aktualności"
      menu={
        <>
          <Link to="add" className="button-primary">
            Dodaj newsa
          </Link>
        </>
      }
    >
      <div className="rod-count">Ilość elementów: {dataList.length}</div>

      <div className={styles.list}>
        <table className="rod-table">
          <tbody>
            {dataList.map((item) => {
              return (
                <tr key={item.id}>
                  <td>
                    <img src={item.visible ? seenSvg : unseenSvg} className={styles.eye} alt="Czy opublikowano?" />
                  </td>
                  <td>
                    <span className={styles.date}> {format(new Date(item.created_at), 'dd.MM.yyyy')}</span>
                    <span className={styles.name}> {item.title}</span>{' '}
                  </td>

                  <td className="rod-table-right">
                    <Link to={`edit/${item.id}`} className="rod-table-button-primary">
                      Edytuj
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Page>
  );
};

export default List;
