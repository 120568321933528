import Number from '../../Number';
import styles from './Measures.module.scss';
import cn from 'classnames';
import { useMemo } from 'react';

const Measures = ({ measures = [], year }) => {
  const currentYear = new Date().getFullYear();

  const data = useMemo(() => {
    const arr = [];

    for (let i = 0; i < measures.length; i++) {
      if (i % 2 === 0) {
        arr.push([]);
      }

      arr[arr.length - 1].push(measures[i]);
    }

    return arr;
  }, [measures]);

  return (
    <div className={styles.measures}>
      <div className={styles.list}>
        {data.map((block, index) => {
          return (
            <div key={index}>
              <div className={cn(styles.item, block[1] === undefined && styles.incomplete)}>
                {block[0]}
                <span className={styles.separator}>→</span>
                {block[1] === undefined ? (
                  '?'
                ) : (
                  <>
                    {block[1]}
                    <span className={styles.delta}>
                      (+
                      <Number value={block[1] - block[0]} decimalScale={1} />)
                    </span>
                    <span className={styles.unit}>
                      m<sup>3</sup>
                    </span>
                  </>
                )}
              </div>
            </div>
          );
        })}
        {data.length < 1 && currentYear - 1 === year && <div>-</div>}
      </div>
    </div>
  );
};

export default Measures;
