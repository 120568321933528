const FixedNumberInput = ({ field, fractionDigits = 2, intDigits = 6, placeholder }) => {
  const div = Math.pow(10, fractionDigits);

  let v = (field.value / div).toFixed(fractionDigits) || '';

  if (isNaN(v)) {
    v = '';
  }

  return (
    <input
      placeholder={placeholder}
      inputMode="numeric"
      type="text"
      value={v}
      onChange={(e) => {
        const possibleNumber = (e.target.value || '')
          .replace('.', '')
          .replace(/\D/g, '')
          .substring(0, fractionDigits + intDigits);

        field.onChange({
          target: {
            value: possibleNumber ? possibleNumber : '',
          },
        });
      }}
    />
  );
};

export default FixedNumberInput;
