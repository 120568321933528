import Page from '../../Page';
import { entries } from '../../httpClient/checklists';
import makeNames from '../../utils/makeNames';
import styles from './Entries.module.scss';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';

const VALUES = {
  1: 'tak',
  2: 'nie',
  3: 'nie dotyczy',
};

const Entries = () => {
  const { id } = useParams();
  const [dataList, setDataList] = useState([]);
  const [title, setTitle] = useState('Check-listy');

  useEffect(() => {
    const get = async () => {
      try {
        const response = await entries(id);

        setDataList(response.data.entries);
        setTitle('Checklista ' + response.data.details.name);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    get();
  }, [id]);

  return (
    <Page title={title} back="/checklists" breadcrumbs={[['/checklists', 'Check-listy']]}>
      <div>
        <table className="rod-table">
          <thead>
            <tr>
              <th className="rod-table-left"></th>
              <th className="rod-table-left">Imię i nazwisko</th>
              <th className="rod-table-left">Wartość</th>
              <th className="rod-table-right">Akcje</th>
            </tr>
          </thead>

          <tbody>
            {dataList.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.userId}</td>
                  <td>{makeNames(item)}</td>
                  <td>
                    {VALUES[item.value]}
                    {item.accountName ? <span className={styles.modified}>({item.accountName})</span> : ''}
                  </td>

                  <td className="rod-table-right">
                    <Link to={`/checklists/entry/${item.id}`} className="rod-table-button-primary">
                      Edytuj
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>

          <tfoot>
            <tr>
              <td colSpan={5} className="rod-table-summary">
                Ilość elementów: {dataList.length}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </Page>
  );
};

export default Entries;
