import Page from '../../Page';
import Envelope from '../Envelope';
import GardenList from '../GardenList';
import Invitation from '../Invitation';
import Label from '../Label';
import Monit from '../Monit';
import Vouchers from '../Vouchers';
import styles from './List.module.scss';
import { useState } from 'react';

const Modal = ({ handleClose }) => {
  const [title, setTitle] = useState('');

  return (
    <div className="rod-modal">
      <div className="rod-modal-container">
        <div className={styles.spread}>
          <div>
            <div className="rod-form-row">
              <label>Tytuł listy</label>
              <input
                type="text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>

            <div className={styles.generateButton}>{title && <GardenList title={title} />}</div>
          </div>
          <div className="rod-modal-close">
            <button onClick={handleClose}>Zamknij okno</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const List = () => {
  const [open, setOpen] = useState(false);

  return (
    <Page title="Dokumenty generowane">
      <div className={styles.list}>
        <div className="rod-count">Ilość elementów: 5</div>

        <table className="rod-table">
          <tbody>
            <tr>
              <td>Etykiety na koperty</td>
              <td className="rod-table-right">
                <Label />
              </td>
            </tr>

            <tr>
              <td>Talony</td>
              <td className="rod-table-right">
                <Vouchers />
              </td>
            </tr>

            <tr>
              <td>Nadruk firmowy na kopertę</td>
              <td className="rod-table-right">
                <Envelope />
              </td>
            </tr>

            <tr>
              <td>Zaproszenie na Walne</td>
              <td className="rod-table-right">
                <Invitation />
              </td>
            </tr>

            <tr>
              <td>Wezwanie do płatności za prąd</td>
              <td className="rod-table-right">
                <Monit />
              </td>
            </tr>

            <tr>
              <td>Lista nazwisk z tytułem</td>
              <td className="rod-table-right">
                {open && (
                  <Modal
                    handleClose={() => {
                      setOpen(false);
                    }}
                  />
                )}

                <button
                  className="rod-table-button-special"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  PDF
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Page>
  );
};

export default List;
