import Number from '../../Number';
import Page from '../../Page';
import { list } from '../../httpClient/electricity';
import makeNames from '../../utils/makeNames';
import styles from './List.module.scss';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const List = () => {
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const get = async () => {
      try {
        const response = await list();

        setDataList(response.data);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    get();
  }, []);

  return (
    <Page title="Prąd">
      <div className={styles.list}>
        <table className="rod-table">
          <thead>
            <tr>
              <th className="rod-table-left"></th>
              <th className="rod-table-left">Imię i nazwisko</th>
              <th className="rod-table-left">Opłata</th>
              <th className="rod-table-left"></th>
            </tr>
          </thead>

          <tbody>
            {dataList.map((item) => {
              const sum = item.entries?.reduce((acc, curr) => acc + curr.value, 0);

              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{makeNames(item)}</td>
                  <td className="rod-table-nowrap">
                    <Number value={sum} />
                  </td>
                  <td>
                    {sum >= 1000 ? <span className={styles.ok}>✔</span> : sum < 1000 && sum > 0 ? <span className={styles.ok}>◯</span> : ''}
                  </td>
                </tr>
              );
            })}
          </tbody>

          <tfoot>
            <tr>
              <td colSpan={4} className="rod-table-summary">
                Ilość elementów: {dataList.length}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </Page>
  );
};

export default List;
