import Page from '../../Page';
import { list } from '../../httpClient/users';
import { add } from '../../httpClient/waterMeters';
import FixedNumberInput from '../../utils/FixedNumberInput';
import { NOT_DATE, NOT_INTEGER_NUMBER, NOT_NUMBER, NOT_POSITIVE_NUMBER, REQUIRED } from '../../utils/formTexts';
import styles from './Add.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import { forwardRef, useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

// the locale you want

registerLocale('pl', pl); // register it with the name you want

const TIME_FORMAT = 'yyyy-MM-dd';

const schema = yup
  .object({
    gardenId: yup.number().typeError(REQUIRED).positive(REQUIRED).integer(REQUIRED).required(REQUIRED),
    time: yup.date().typeError(NOT_DATE).required(REQUIRED),
    value: yup.number().typeError(NOT_NUMBER).required(REQUIRED),
    size: yup.number().typeError(NOT_NUMBER),
    file: yup.mixed().test('file', REQUIRED, (value) => {
      if (value.length > 0) {
        return true;
      }
      return false;
    }),
  })
  .required();

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="input" onClick={onClick} ref={ref}>
    {value}
  </div>
));

const Add = () => {
  const [users, setUsers] = useState([]);
  const [added, setAdded] = useState(false);
  const [blob, setBlob] = useState();

  const { id } = useParams();

  useEffect(() => {
    const get = async () => {
      try {
        const response = await list();
        const users = response.data.filter((item) => item.waterMeter).map((item) => ({ id: item.id, name: item.names }));

        setUsers(users);
      } catch (e) {}
    };

    get();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      time: new Date(),
    },
  });

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        reset({
          gardenId: id,
        });
      }, 100);
    }
  }, [id, reset]);

  const watchFile = watch('file');

  useEffect(() => {
    if (watchFile && watchFile.length > 0) {
      setBlob(URL.createObjectURL(watchFile[0]));
    }
  }, [watchFile]);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('file', data.file[0]);
      formData.append('time', format(data.time, TIME_FORMAT));
      formData.append('value', data.value / 1000);
      formData.append('size', data.size);
      formData.append('gardenId', data.gardenId);

      await add(formData);

      toast.success('Dane zostały zapisane');
      setAdded(true);
    } catch (e) {}
  };

  return (
    <>
      <Page title="Dodawanie nowego odczytu wodomierza" back="/watermeters" breadcrumbs={[['/watermeters', 'Wodomierze']]}>
        <div className="rod-form">
          <div className="rod-form-content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="rod-form-row">
                <label>Numer działki *</label>
                <select disabled={id !== undefined} {...register('gardenId')}>
                  <option value="" />
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.id}. {user.name}
                    </option>
                  ))}
                </select>
                {errors.gardenId && <div className="rod-form-error">{errors.gardenId?.message}</div>}
              </div>
              <div className="rod-form-row">
                <label>Data odczytu *</label>
                <Controller
                  control={control}
                  name="time"
                  render={({ field }) => (
                    <ReactDatePicker
                      dateFormat={TIME_FORMAT}
                      onChange={(e) => field.onChange(e)}
                      selected={field.value}
                      customInput={<ExampleCustomInput />}
                      locale="pl"
                    />
                  )}
                />
                {errors.time && <div className="rod-form-error">{errors.time?.message}</div>}
              </div>

              <div className="rod-form-row">
                <label>Zdjęcie *</label>

                <div className={styles.fileWrapper}>
                  {!blob && <label>Dodaj</label>}

                  {blob && <img src={blob} alt="Preview" />}

                  <input type="file" {...register('file')} accept=" image/jpeg, image/png" />
                </div>

                {errors.file && <div className="rod-form-error">{errors.file?.message}</div>}
              </div>

              <div className="rod-form-row">
                <label>Wartość odczytu *</label>

                <Controller
                  control={control}
                  name="value"
                  render={({ field }) => <FixedNumberInput field={field} fractionDigits={3} intDigits={5} />}
                />

                {errors.value && <div className="rod-form-error">{errors.value?.message}</div>}
              </div>

              <div className="rod-form-row">
                <label>Rozmiar wodomierza *</label>

                <select {...register('size')}>
                  <option value={1}>1/2''</option>
                  <option value={2}>3/4''</option>
                </select>

                {errors.size && <div className="rod-form-error">{errors.size?.message}</div>}
              </div>

              <div className="rod-form-legend">
                <ul>
                  <li>* - pole wymagane</li>
                </ul>
              </div>

              <div className="rod-form-submit">
                <input disabled={added} type="submit" className="button-primary" value="Zapisz" />
                <input
                  className="button-secondary"
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                    setAdded(false);
                    setBlob(undefined);
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </Page>
    </>
  );
};

export default Add;
