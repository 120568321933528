import DialogModal from '../../DialogModal';
import Modal from '../../GardenModal';
import Page from '../../Page';
import { list } from '../../httpClient/users';
import { UsersListState } from '../../store/users';
import makeNames from '../../utils/makeNames';
import removeDiacritics from '../../utils/removeDiacritics';
import Avatar, { makeInitials } from './Avatar';
import CallTo from './CallTo';
import styles from './List.module.scss';
import clearSvg from './assets/clear.svg';
import filtersSvg from './assets/filters.svg';
import useCsvData from './hooks/useCsvData';
import cn from 'classnames';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import { Download, Search, UserPlus } from 'lucide-react';
import { Pencil, Phone, PhoneOff, Trash2 } from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { LeadingActions, SwipeAction, SwipeableList, SwipeableListItem, TrailingActions, Type } from 'react-swipeable-list';
import { useRecoilState } from 'recoil';

const getAddress = (user) => {
  let address = '';

  address += user.street ? `${user.street} ${user.building} ${user.flat ? `/ ${user.flat}` : ''}` : '';
  address += user.city ? `${user.street ? ', ' : ''}${user.postcode} ${user.city}` : '';

  return address;
};

const leadingActions = (count, phones, userId, cb) => (
  <LeadingActions>
    {count < 1 && (
      <SwipeAction onClick={() => {}}>
        <div className={cn(styles.actions, styles.blocked)}>
          <PhoneOff size={24} color="white" />
        </div>
      </SwipeAction>
    )}

    {count > 0 && (
      <SwipeAction
        onClick={() => {
          cb(count, phones, userId);
        }}
      >
        <div className={cn(styles.calltoUser, styles.phone)}>
          <div className={styles.phoneWrapper}>
            <Phone size={24} color="white" />
          </div>
        </div>
      </SwipeAction>
    )}
  </LeadingActions>
);

const trailingActions = (navigate, id) => (
  <TrailingActions>
    <SwipeAction onClick={() => navigate(`/users/edit/${id}`)}>
      <div className={cn(styles.actions, styles.edit)}>
        <Pencil size={24} color="white" />
      </div>
    </SwipeAction>
    <SwipeAction onClick={() => navigate(`/users/remove/${id}`)}>
      <div className={cn(styles.actions, styles.remove)}>
        <Trash2 size={24} color="white" />
      </div>
    </SwipeAction>
  </TrailingActions>
);

const makePhoneUri = (p) => {
  const number = parsePhoneNumber(p, 'PL');

  return {
    name: new AsYouType('PL').input(number.number),
    uri: number.getURI(),
  };
};

const List = () => {
  const [users, setUsers] = useRecoilState(UsersListState);
  const [selectedGarden, setSelectedGarden] = useState(undefined);
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();
  const vibroRef = useRef(false);
  const [dialogUserId, setDialogUserId] = useState(undefined);

  const makeCall = useCallback(
    (count, phones, userId) => {
      if (count === 1) {
        phones = phones.filter(Boolean);
        const phone = makePhoneUri(phones[0]);

        const dlink = document.createElement('a');
        dlink.href = phone.uri;
        dlink.click();
        dlink.remove();
      }

      if (count === 2) {
        setDialogUserId(userId);
      }
    },
    [users]
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await list();

        setUsers(response.data);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    getData();
  }, [setUsers]);

  const csvData = useCsvData(users);

  const filteredUserList = useMemo(() => {
    if (!filter) {
      return users;
    }

    return users.filter((user) => {
      const names = removeDiacritics(makeNames(user)).toLowerCase();
      const normalizedFilter = removeDiacritics(filter).toLowerCase();

      return names.indexOf(normalizedFilter) >= 0 || user.id.toString() === normalizedFilter;
    });
  }, [users, filter]);

  return (
    <Page title="Działkowcy">
      <div className={styles.links}>
        <Link to="add">
          <div className={styles.icon}>
            <UserPlus size={28} />
          </div>
          Dodaj
        </Link>
        {csvData && (
          <CSVLink data={csvData} filename={'contacts.csv'} enclosingCharacter={``} separator={';'} className={styles.icon}>
            <div className={styles.icon}>
              <Download size={28} />
            </div>
            Pobierz
          </CSVLink>
        )}
      </div>

      <div className={styles.search}>
        <div className={styles.searchInput}>
          <Search size={16} color="#4b4b4b" />

          <input
            type="text"
            placeholder="Szukaj..."
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          />

          {filter && (
            <img
              src={clearSvg}
              alt="filter"
              onClick={() => {
                setFilter('');
              }}
            />
          )}
        </div>

        <button>
          <img src={filtersSvg} alt="filter" />
        </button>
      </div>

      <SwipeableList type={Type.IOS} threshold={0.5}>
        {filteredUserList.map((user, index) => {
          const phones = [user.phone, user.subOwnerPhone].filter(Boolean);

          return (
            <SwipeableListItem
              maxSwipe={0.5}
              key={user.id}
              leadingActions={leadingActions(phones.length, phones, user.id, makeCall)}
              trailingActions={trailingActions(navigate, user.id)}
              className={styles.swipeable}
              onClick={() => {
                setSelectedGarden(user.id);
              }}
              onSwipeProgress={(p) => {
                if (vibroRef.current === false) {
                  if (p >= 50) {
                    navigator.vibrate([50]);

                    vibroRef.current = true;
                  }
                }
              }}
              onSwipeEnd={() => {
                vibroRef.current = false;
              }}
            >
              <div className={styles.user}>
                <Avatar user={user} />
                <div>
                  <div className={styles.name}>
                    {user.id}
                    {'. '}
                    {user.names}
                    {Boolean(user.hasTwoOwners) && <span>, {user.subOwnerNames}</span>}
                  </div>
                  <div className={styles.address}>{getAddress(user)}</div>
                </div>
              </div>
            </SwipeableListItem>
          );
        })}
      </SwipeableList>

      <div className={styles.count}>Ilość elementów: {filteredUserList.length}</div>

      {selectedGarden && (
        <Modal
          id={selectedGarden}
          handleClose={() => {
            setSelectedGarden(undefined);
          }}
          forceBig
        />
      )}

      {dialogUserId && (
        <DialogModal
          handleClose={() => {
            setDialogUserId(undefined);
          }}
        >
          <CallTo user={users.find((u) => u.id === dialogUserId)} />
        </DialogModal>
      )}
    </Page>
  );
};

export default List;
