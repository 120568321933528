import Page from '../../Page';
import Select from '../../Select';
import { list } from '../../httpClient/waterMeters';
import Measures from '../Measures';
import styles from './List.module.scss';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const List = () => {
  const [dataList, setDataList] = useState([]);
  const [year, setYear] = useState(2024);

  const years = useMemo(() => {
    const currentYear = year;

    return [currentYear - 1, currentYear];
  }, [year]);

  useEffect(() => {
    const get = async () => {
      try {
        const response = await list(true, year);

        setDataList(response.data);
      } catch (e) {
        toast.error('Nie można pobrać danych');
      }
    };

    get();
  }, [year]);

  console.log(dataList);

  return (
    <Page
      title="Wodomierze"
      menu={
        <Link to="add" className="button-primary">
          Dodaj odczyt
        </Link>
      }
    >
      <Select
        onChange={(v) => {
          setDataList([]);
          setYear(v);
        }}
        value={year}
      />

      <div className={styles.list}>
        <div className={styles.count}>Ilość elementów: {dataList.length}</div>

        {dataList.map((item) => {
          return (
            <div key={item.id} className={styles.box}>
              <div className={styles.header}>
                <div className={styles.name}>Działka nr {item.id}</div>
                <div>
                  <Link to={`details/${item.id}`} className="rod-table-button-primary">
                    Szczegóły
                  </Link>
                </div>
              </div>
              <div className={styles.measures}>
                {years.map((y) => (
                  <div key={y} className={styles.measure}>
                    <div className={styles.year}>{y}</div>
                    <Measures measures={item.measures[y].map((measure) => measure.value)} year={y} />
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </Page>
  );
};

export default List;
